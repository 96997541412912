import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Meta } from 'modules/shared/interfaces';

const initialState = {
  report: <unknown[]>[],
  loading: false,
  meta: { page: 1, take: 25, itemCount: 0, pageCount: 0, hasPreviousPage: false, hasNextPage: false },
};

export const reportsSlice = createSlice({
  name: 'reports',
  initialState: initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setReports(state, action: PayloadAction<unknown[]>) {
      state.report = action.payload;
    },
    setMeta(state, action: PayloadAction<Meta>) {
      state.meta = action.payload;
    },
    setReset() {
      return initialState;
    },
  },
});

export const reportsActions = reportsSlice.actions;

export default reportsSlice;
