import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ScheduleData } from 'modules/alerts/interfaces/dataGrid.interface';
import { Meta } from 'modules/shared/interfaces';
import { AlertInterface, AlertType } from 'modules/shared/interfaces/alerts.interface';

export const alertsSlice = createSlice({
  name: 'alerts',
  initialState: {
    loading: false,
    alertsList: [] as AlertInterface[],
    selectedAlert: {} as AlertInterface,
    alertTypes: [] as AlertType[],
    csvVolumeGrid: [] as ScheduleData[],
    meta: {} as Meta,
  },
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    addAlert: (state, action: PayloadAction<AlertInterface>) => {
      state.alertsList.push(action.payload);
    },
    removeAlert: (state, action) => {
      state.alertsList = state.alertsList.filter(alert => alert.alertTypeId !== action.payload);
    },
    setAlertsList: (state, action: PayloadAction<AlertInterface[]>) => {
      state.alertsList = action.payload;
    },
    setAlertTypes: (state, action: PayloadAction<AlertType[]>) => {
      state.alertTypes = action.payload;
    },
    setSelectedAlert: (state, action: PayloadAction<AlertInterface>) => {
      state.selectedAlert = action.payload;
    },
    setCsvVolumeGrid: (state, action: PayloadAction<ScheduleData[]>) => {
      state.csvVolumeGrid = action.payload;
    },
    setMeta: (state, action: PayloadAction<Meta>) => {
      state.meta = action.payload;
    },
    resetAlerts: state => {
      state.alertsList = [] as AlertInterface[];
    },
    resetSelectedAlert: state => {
      state.selectedAlert = {} as AlertInterface;
    },
    resetMeta: state => {
      state.meta = {} as Meta;
    },
  },
});

export const alertsActions = alertsSlice.actions;

export default alertsSlice;
