import { SocketContext } from 'modules/core/providers/webSocket.provider';
import { useContext, useEffect } from 'react';
import { Socket } from 'socket.io-client';
interface SocketContextType {
  events: { [key: string]: (data: any) => void } | null;
}

export const useSocket = ({ events }: SocketContextType): Socket | null => {
  const socket = useContext(SocketContext);
  useEffect(() => {
    if (socket && events) {
      // Register each event listener
      Object.entries(events).forEach(([event, handler]) => {
        socket.on(event, handler);
      });

      // Cleanup: Remove listeners on unmount or when `events` changes
      return () => {
        Object.entries(events).forEach(([event, handler]) => {
          socket.off(event, handler);
        });
      };
    }
  }, [socket, events]);

  return socket;
};
