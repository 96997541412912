import { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import SaveIcon from '@mui/icons-material/Save';
import AppHeader from 'modules/shared/components/header/AppHeader.component';
import FormCalculator from './components/FormCalculator';
import Overlay from 'modules/shared/components/Overlay/Overlay.component';
import { InboundCalculatorProvider } from 'modules/core/providers/InboundCalculator.provider';
import { VariationsAgentsTable } from './components/VariationsAgentsTable';
import { YourResult } from 'modules/shared/components/yourResult/YourResult.component';
import { AboutErlangCalculator } from './components/AboutErlangCalculator';
import { InboundCalculationData, postInboundCalculation } from './services/inboundCalculator.service';
import SaveResultsInbound from './components/SaveResultsInbound';
import { useInboundCalculator } from '../core/providers/InboundCalculator.provider';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';
import { getCampaigns, getCampaignsForSelect } from 'modules/campaigns/services';
import { useForm } from 'react-hook-form';
import { DownloadCSVLink } from 'modules/shared/components/downloadCSVLink/DownloadCSVLink.component';
const InboundCalculatorBase = (): JSX.Element => {
  const navigate = useNavigate();
  const { campaignID } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedRowData, suggestedRowData, calculatorData, setCalculatorFields } = useInboundCalculator();
  const { handleSubmit, register, control, reset, setValue } = useForm<any>({
    defaultValues: calculatorData,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [saveError, setSaveError] = useState('');
  const [calculationResult, setCalculationResult] = useState<InboundCalculationData[]>([]);
  const [calculatorIsExpanded, setCalculatorIsExpanded] = useState(true);
  const [resultsAreHidden, setResultsAreHidden] = useState(true);
  const [campaignsInbound, setCampaignsInbound] = useState<any[]>([]);
  const [openSaveModal, setOpenSaveModal] = useState(false);

  const handleToggleModal = () => setOpenSaveModal(prevState => !prevState);

  const handleCompleteCalculation = (data?: InboundCalculationData[]) => {
    if (data) {
      setCalculationResult(data);

      setCalculatorIsExpanded(false);
    } else {
      setResultsAreHidden(true);
    }
    setIsLoading(false);
  };

  const handleStartCalculation = () => {
    setIsLoading(true);
    setResultsAreHidden(false);
  };

  const addCalculatorInbound = () => {
    handleToggleModal();
  };
  const saveCalculation = async (name: string) => {
    setIsSaving(true);
    if (selectedRowData && calculatorData) {
      try {
        await postInboundCalculation({
          name,
          campaignId: calculatorData.campaign,
          incomingContactsInput: calculatorData.incomingContacts,
          periodInMinutesInput: calculatorData.periodContacts,
          ahtInput: calculatorData.averageInteractionTime,
          serviceLevelPercentageRequiredInput: calculatorData.serviceLevelRequired,
          targetTimeInput: calculatorData.responseTime,
          maxOccupacionInput: calculatorData.maxOccupation,
          reductoresInput: calculatorData.reducers,
          avgWaitTimeInSecondsInput: calculatorData.averagePatience,
          weeklyV1fteInHoursInput: calculatorData.weeklyHours,
          agentsOutput: selectedRowData.raw_positions,
          sContractionOutput: selectedRowData.agents,
          serviceLevelPercentageOutput: selectedRowData.service_level_percentage,
          occupancyOutput: selectedRowData.occupancy,
          abandonRatePercentageOutput: selectedRowData.abandon_rate_percentage,
          answeredImmediatelyPercentageOutput: selectedRowData.answered_immediately_percentage,
          asaOutput: selectedRowData.asa,
        });

        setIsSaving(false);
        enqueueSnackbar('Cálculo guardado correctamente', { variant: 'success' });
        setOpenSaveModal(false);
        navigate(`/campaigns/${calculatorData.campaign}?tab=calcs`);
      } catch (error) {
        setSaveError((error as any).response?.data?.message?.message || (error as Error).message);
        setIsSaving(false);
      }
    }
  };

  const normalizePercentage = (value = 0, decimals = 1) => {
    return parseFloat(Number(value * 100).toFixed(decimals));
  };

  useEffect(() => {
    const getCampaignsInbound = async () => {
      const campaigns = await getCampaignsForSelect({
        field: 'type',
        value: 'inbound',
      });
      let campaignList: any = [];
      if (campaignID) {
        const foundCampaign = campaigns.find((i: any) => i.id === campaignID);
        campaignList = [foundCampaign];
      } else {
        campaignList = campaigns;
      }

      const getCurrentUserCampaigns = async () => {
        const campaigns = await getCampaigns({ field: 'type', value: 'inbound' });
        const campaignsList = campaigns.campaigns;
        return campaignsList;
      };

      const currentUserCampaigns = getCurrentUserCampaigns();

      const getUserInboundCampaigns = (campaignList: any[], currentUserCampaigns: any[]) => {
        return campaignList.filter(camp1 => {
          return currentUserCampaigns.some(camp2 => {
            return camp1.id === camp2.id;
          });
        });
      };

      const filteredInboundCampaigns = getUserInboundCampaigns(campaignList, await currentUserCampaigns);
      setCampaignsInbound(filteredInboundCampaigns);

      if (campaignList.length === 1) {
        setValue('campaign', campaignList[0].id);
        setCalculatorFields({ ...calculatorData, campaign: campaignList[0].id });
      }
    };

    getCampaignsInbound();
  }, [campaignID]);

  const newName =
    campaignsInbound.length > 0 && calculatorData.campaign
      ? campaignsInbound.find(i => i.id === calculatorData.campaign).name
      : '';

  const csvValues = calculationResult.map(i => ({
    ...i,
    service_level_percentage: normalizePercentage(i.service_level_percentage, 2),
    occupancy: normalizePercentage(i.occupancy, 2),
    answered_immediately_percentage: normalizePercentage(i.answered_immediately_percentage, 2),
    abandon_rate_percentage: normalizePercentage(i.abandon_rate_percentage, 2),
  }));

  return (
    <>
      <AppHeader
        pageTitle="Calculadora"
        breadcrumbs={[
          { label: 'Inicio', href: '/' },
          { label: 'Campañas', href: '/campaigns' },
          { label: 'Calculadora' },
        ]}
        headerActions={
          <div>
            <DownloadCSVLink
              data={csvValues.length ? csvValues : ''}
              headers={[
                { label: 'Agentes', key: 'raw_positions' },
                { label: 'Agente s/ contracción', key: 'agents' },
                { label: 'Nivel de servicio (%)', key: 'service_level_percentage' },
                { label: 'Ocupación (%)', key: 'occupancy' },
                { label: 'ASA (S)', key: 'asa' },
                { label: 'Respuesta Inmediata (%)', key: 'answered_immediately_percentage' },
                { label: 'Tasa Abandono', key: 'abandon_rate_percentage' },
              ]}
              disabled={!csvValues.length || calculatorIsExpanded}
              filename={`calculo-variacion-agentes--camp-${newName}`}
            >
              <Button
                variant="contained"
                color="primary"
                endIcon={<FileDownloadOutlinedIcon />}
                disabled={!calculationResult.length || calculatorIsExpanded}
              >
                EXPORTAR TABLA DE DATOS (CSV)
              </Button>
            </DownloadCSVLink>
            <Button
              onClick={handleToggleModal}
              variant="contained"
              color="primary"
              endIcon={<SaveIcon />}
              disabled={!selectedRowData || calculatorIsExpanded}
            >
              GUARDAR
            </Button>
          </div>
        }
      />
      <SaveResultsInbound
        modal={openSaveModal}
        onCloseModal={addCalculatorInbound}
        onConfirm={saveCalculation}
        isSaving={isSaving}
        errorMessage={saveError}
      />
      <InboundCalculatorContainer>
        <Collapse in={!calculationResult.length}>
          <div>
            <AboutErlangCalculator />
          </div>
        </Collapse>

        <FormCalculator
          expand={calculatorIsExpanded}
          showExpandButton={!!calculationResult.length}
          onExpand={value => setCalculatorIsExpanded(value)}
          onCompleteCalculation={handleCompleteCalculation}
          onStartCalculation={handleStartCalculation}
          campaignsInbound={campaignsInbound}
          control={control}
          handleSubmit={handleSubmit}
          register={register}
          reset={reset}
        />

        <Collapse in={!resultsAreHidden}>
          <div>
            <Overlay isDisabled={calculatorIsExpanded || isLoading} isLoading={isLoading}>
              <YourResult
                averageResponseSpeed={suggestedRowData ? suggestedRowData?.asa.toFixed(2) : calculatorData.responseTime}
                necessaryAgents={suggestedRowData?.raw_positions || 0}
                occupationPercentage={normalizePercentage(suggestedRowData?.occupancy || 0)}
                responseTime={calculatorData.responseTime}
                serviceLevelPercentage={normalizePercentage(suggestedRowData?.service_level_percentage || 0)}
                shrinkagePercentageIncluded={calculatorData.reducers ? calculatorData.reducers : 0}
                totalCalls={calculatorData.incomingContacts}
              />
            </Overlay>
          </div>
        </Collapse>

        <Collapse in={!resultsAreHidden}>
          <div>
            <Overlay isDisabled={calculatorIsExpanded || isLoading} isLoading={isLoading}>
              <VariationsAgentsTable
                rows={calculationResult.map((item, index) => ({
                  abandonmentRate: item.abandon_rate_percentage,
                  agents: item.raw_positions,
                  agentsWithoutContraction: item.agents,
                  asa: item.asa,
                  immediateResponse: item.answered_immediately_percentage,
                  occupation: item.occupancy,
                  serviceLevel: item.service_level_percentage,
                  id: index,
                  isSuggested: item.highlighted,
                }))}
                campaignName={newName}
              />
            </Overlay>
          </div>
        </Collapse>
      </InboundCalculatorContainer>
    </>
  );
};

const InboundCalculator = () => {
  return (
    <InboundCalculatorProvider>
      <InboundCalculatorBase />
    </InboundCalculatorProvider>
  );
};

export default InboundCalculator;

const InboundCalculatorContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
}));
