import { format } from 'date-fns';
import { getSmartNotifications, readNotifications } from 'modules/layouts/services';
import { queryParamsType, QueryToSend } from 'modules/shared/interfaces';
import { WebSocketNotification } from 'modules/shared/interfaces/notifications.interface';
import { AppDispatch } from 'store';
import { notificationsActions } from 'store/slices/notifications.slice';

interface UpdateNotificationPayload {
  id: string;
  changes: Partial<WebSocketNotification>;
}

export const fetchNotifications = (queryParams?: queryParamsType) => {
  return async (dispatch: AppDispatch) => {
    try {
      let result;
      const queryToSend: QueryToSend = {};
      dispatch(notificationsActions.setLoading(true));
      if (queryParams?.take) queryToSend.take = queryParams.take;
      if (queryParams?.page) queryToSend.page = queryParams.page;

      const start = new Date(queryParams?.startDate as string);
      if (queryParams?.startDate) queryToSend.start_date = format(start, 'yyyy-MM-dd');

      const end = new Date(queryParams?.endDate as string);
      if (queryParams?.endDate) queryToSend.end_date = format(end, 'yyyy-MM-dd');

      if (queryParams?.searchBy && queryParams?.searchText) {
        const { searchBy, searchText } = queryParams;

        queryToSend.value = searchText;
        queryToSend.field = searchBy;
        if (searchBy === 'name') queryToSend.field = 'name';
        if (searchBy === 'notificationType') queryToSend.field = 'types';
        if (searchBy === 'campaign') queryToSend.field = 'campaigns';
      }
      if (Object.keys(queryToSend).length) {
        result = await getSmartNotifications(queryToSend);
      } else {
        result = await getSmartNotifications();
      }

      dispatch(notificationsActions.setNotifications(result.data));
      dispatch(notificationsActions.setMeta(result.meta));
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(notificationsActions.setLoading(false));
    }
  };
};

export const resetMeta = () => {
  return (dispatch: AppDispatch) => {
    dispatch(notificationsActions.resetMeta());
  };
};

export const addNotification = (notification: WebSocketNotification) => {
  return (dispatch: AppDispatch) => {
    dispatch(notificationsActions.pushNotification(notification));
  };
};

export const resetNotifications = () => {
  return (dispatch: AppDispatch) => {
    dispatch(notificationsActions.resetNotifications());
  };
};

export const updateNotificationById = (payload: UpdateNotificationPayload) => {
  return async (dispatch: AppDispatch) => {
    try {
      await readNotifications([{ id: payload.id }]);

      dispatch(notificationsActions.updateNotificationById(payload));
    } catch (e) {
      console.error('Failed to update notification:', e);
    }
  };
};
