import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Meta } from 'modules/shared/interfaces';
import { WebSocketNotification } from 'modules/shared/interfaces/notifications.interface';

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: {
    loading: false,
    meta: {} as Meta,
    notifications: [] as WebSocketNotification[],
  },
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setMeta: (state, action: PayloadAction<Meta>) => {
      state.meta = action.payload;
    },
    resetMeta: state => {
      state.meta = {} as Meta;
    },
    setNotifications: (state, action: PayloadAction<WebSocketNotification[]>) => {
      state.notifications = action.payload;
    },
    pushNotification: (state, action: PayloadAction<WebSocketNotification>) => {
      state.notifications.unshift(action.payload);
    },
    resetNotifications: state => {
      state.notifications = [] as WebSocketNotification[];
    },
    updateNotificationById: (state, action: PayloadAction<{ id: string; changes: Partial<WebSocketNotification> }>) => {
      const { id, changes } = action.payload;
      const notificationIndex = state.notifications.findIndex(notification => notification.payload.id === id);
      if (notificationIndex !== -1) {
        state.notifications[notificationIndex] = {
          ...state.notifications[notificationIndex],
          ...changes,
        };
      }
    },
  },
});

export const notificationsActions = notificationsSlice.actions;

export default notificationsSlice;
