export enum EnumConflicts {
  AEnviar = 'a enviar',
  EnRevisión = 'revision',
  Revisados = 'revisados',
}

export enum EnumNotifications {
  todas = 'todas',
  nuevas = 'nuevas',
  leidas = 'leidas',
}
