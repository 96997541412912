import { Chip } from '@mui/material';
import { SchedulerModalEmployee } from '../interfaces/Calendar.interface';

export const getShiftChip = (shifts: string) => {
  if (shifts && shifts !== 'Sin Turno') {
    return <Chip sx={{ backgroundColor: SchedulerModalEmployee.SHIFT }} label={shifts}></Chip>;
  } else {
    return <Chip sx={{ backgroundColor: SchedulerModalEmployee.NOTURN }} label="Sin Turno"></Chip>;
  }
};

export const getTeamChip = (teams: string) => {
  if (teams && teams !== 'Sin Equipo') {
    return <Chip sx={{ backgroundColor: SchedulerModalEmployee.TEAM, m: 1 }} label={teams}></Chip>;
  } else {
    return <Chip sx={{ backgroundColor: SchedulerModalEmployee.NOTURN, m: 1 }} label="Sin Equipo"></Chip>;
  }
};

export const getCampaignChip = (campaigns: string) => {
  if (campaigns && campaigns !== 'Sin Campaña') {
    return <Chip sx={{ backgroundColor: SchedulerModalEmployee.CAMPAING }} label={campaigns}></Chip>;
  } else {
    return <Chip sx={{ backgroundColor: SchedulerModalEmployee.NOTURN }} label="Sin Campaña"></Chip>;
  }
};

export default getShiftChip;
