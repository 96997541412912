import React, { useEffect, useState, createContext, useContext } from 'react';
import { io, Socket } from 'socket.io-client';
import { SOCKET_URL } from 'config/config';
import { useAuth } from 'modules/auth/AuthProvider.component';

export const SocketContext = createContext<Socket | null>(null);

export const useSocketContext = () => {
  const context = useContext(SocketContext);

  if (!context) throw new Error('useSocketContext must be use inside SocketContextProvider');
  return context;
};

export const SocketProvider = ({ children }: { children: React.ReactNode }) => {
  const { token } = useAuth();
  const [socket, setSocket] = useState<Socket| null>(null);

  useEffect(() => {
    if (token) {
      const newSocket = io(SOCKET_URL, {
        transportOptions: {
          polling: {
            extraHeaders: {
              authorization: `Bearer ${token}`,
            },
          },
        },
      });

      setSocket(newSocket);

      // Clean up on unmount or token change
      return () => {
        newSocket.disconnect();
        setSocket(null);
      };
    }
  }, [token]);

  return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>;
};
