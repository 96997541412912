import React from 'react';
import { styled } from '@mui/material/styles';
import Tab, { tabClasses } from '@mui/material/Tab';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import { EnumNotifications } from 'modules/scheduler/interfaces/Drawer.interface';

const TabItem = styled(Tab)(({ theme }) => ({
  position: 'relative',
  borderRadius: '5px',
  textAlign: 'center',
  transition: 'all .5s',
  padding: '0 15px',
  color: '#333333',
  backgroundColor: theme?.palette.grey[300],
  height: '32px',
  margin: '0px',
  marginRight: '5px',
  float: 'none',
  fontSize: '12px',
  fontWeight: '500',
  [theme.breakpoints.up('md')]: {
    minWidth: 'auto',
    minHeight: '24px',
    height: '24px',
  },
  [`&.${tabClasses.selected}, &:hover`]: {
    color: '#FFFFFF',
    backgroundColor: theme?.palette.primary.main,
    boxShadow: '0 7px 10px -5px rgba(76, 175, 80, 0.4)',
  },
}));

interface TabsProps {
  tabIndex: EnumNotifications;
  setTabIndex: React.Dispatch<React.SetStateAction<EnumNotifications>>;
}
export const TabsPill = ({ tabIndex, setTabIndex }: TabsProps) => {
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabIndex(newValue as EnumNotifications);
  };

  return (
    <Tabs
      value={tabIndex}
      onChange={handleChange}
      sx={{
        width: '100%',

        [`& .${tabsClasses.indicator}`]: {
          display: 'none',
        },
      }}
    >
      <TabItem disableRipple label={'Todas'} value={EnumNotifications.todas} />
      <TabItem disableRipple label={'Nuevas'} value={EnumNotifications.nuevas} />
      <TabItem disableRipple label={'Leidas'} value={EnumNotifications.leidas} />
    </Tabs>
  );
};
