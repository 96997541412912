import { z } from 'zod';

export interface TeamInterface {
  id: string;
  name: string;
  members: Member[];
  status: boolean;
  member_count: number;
  managers?: Member[];
}

export interface Member {
  names: string;
  surenames: string;
  id: string;
  isManager: boolean;
}

export type MemberToSend = Omit<Member, 'names' | 'surenames'>;

export interface TeamFormToSendInterface {
  name: string;
  members: MemberToSend[];
}

export type TeamFormInterface = Omit<TeamInterface, 'id' | 'status' | 'member_count'>;

export const defaultTeamFormModel: TeamFormInterface = {
  name: '',
  members: [],
};

export const emptyTeam: TeamInterface = {
  id: '',
  name: '',
  members: [],
  member_count: 0,
  status: false,
};

export type TableTeams = Pick<TeamInterface, 'name' | 'member_count' | 'status'> & {
  id: string;
};

const zMember = z.object({
  id: z.string(),
  names: z.string(),
  surenames: z.string(),
  isManager: z.boolean(),
});

export const zTeamResolver = z.object({
  name: z.string().min(5, 'El nombre es requerido'),
  managers: z.array(zMember).min(1, 'Al menos 1 manager es requerido'),
  members: z
    .object({
      id: z.string(),
      names: z.string(),
      isManager: z.boolean(),
    })
    .array(),
});
