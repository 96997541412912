import { httpService } from 'modules/core/services/http.service';
import { PaginationProps } from '../interfaces';
import { QueryStringsParamsType } from 'modules/shared/services';

export const getNotifications = async (paginationProps: PaginationProps) => {
  const res = await httpService.get({
    url: 'notifications',
    params: paginationProps,
  });

  return res.data;
};
export const getSmartNotifications = async (paginationProps?: QueryStringsParamsType) => {
  const res = await httpService.get({
    url: 'smartnotification',
    params: paginationProps,
  });

  return res.data;
};

export const deleteNotification = async (notificationId: string) => {
  const res = await httpService.delete({
    url: `notifications/${notificationId}`,
  });
  return res;
};

export const readNotifications = async (data: { id: string }[]) => {
  const res = await httpService.put({
    url: 'smartnotification',
    payload: data,
  });

  return res.data;
};
