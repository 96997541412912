// thirdparty
import { format } from 'date-fns';
import { es } from 'date-fns/locale';

// components
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Box,
  Chip,
  Divider,
  ListItem,
  ListItemText,
  styled,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { theme } from 'modules/mui/theme';

// hooks
import { useAppDispatch } from 'store/hooks/store.hooks';

// services
import { updateNotificationById } from 'store/actions/notifications.actions';

// interfaces & utils
import { WebSocketNotification } from 'modules/shared/interfaces/notifications.interface';
import { EnumNotifications } from 'modules/scheduler/interfaces/Drawer.interface';

interface Props {
  notifications: WebSocketNotification[];
  tab: EnumNotifications;
}

const NotificationCard = ({ notifications, tab }: Props) => {
  const dispatch = useAppDispatch();
  const filteredNotifications = notifications.filter((item: WebSocketNotification) => {
    switch (tab) {
      case 'todas':
        return true;
      case 'nuevas':
        return !item.payload.wasread;
      case 'leidas':
        return item.payload?.wasread;
      default:
        return false;
    }
  });

  const getNotificationColor = (notification: WebSocketNotification) => {
    if (!notification.payload.wasread && !notification.payload.normalized_at) {
      return '#F49769'; // no leida, no normalizada
    } else if (!notification.payload.wasread && notification.payload.normalized_at) {
      return '#83D783'; // no leida, normalizada
    } else if (notification.payload.wasread && !notification.payload.normalized_at) {
      return '#FFDCB9'; // leida, no normalizada
    } else if (notification.payload.wasread && notification.payload.normalized_at) {
      return '#BDE8BD'; // leida, normalizada
    }
  };

  const formatNotificationDate = (date: string): string => {
    return format(new Date(date), "eeee dd/MM/yy  HH:mm 'hs.'", { locale: es });
  };

  const onOpenAccordion = (notification: WebSocketNotification, expanded: boolean) => {
    if (expanded) {
      const { id, wasread } = notification.payload;

      if (!wasread)
        dispatch(
          updateNotificationById({
            id: id,
            changes: { payload: { ...notification.payload, wasread: true } },
          }),
        );
    }
  };

  return (
    <Box mb={10}>
      {filteredNotifications.map((notification: WebSocketNotification) => (
        <ListItem key={notification.payload.id} sx={{ my: 3, p: 0 }}>
          <Accordion
            onChange={(e, expanded) => {
              onOpenAccordion(notification, expanded);
            }}
            sx={{
              border: theme => `solid 1px ${theme.palette.grey[300]}`,
              borderRadius: '5px',
              position: 'relative',
              width: '100%',
            }}
          >
            <StyledAccordionSummary id="panel-header" aria-controls="panel-content" expandIcon={<ExpandMoreIcon />}>
              <Box
                sx={{
                  top: 0,
                  left: -1,
                  borderRadius: '5px 0 0 5px',
                  bottom: 0,
                  width: 5,
                  position: 'absolute',
                  background: `${getNotificationColor(notification)}`,
                }}
              />
              {!notification.payload.wasread && (
                <AvatarBadge
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  variant="dot"
                />
              )}
              <ListItemText
                disableTypography
                primary={
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'flex-start',
                    }}
                  >
                    <Typography variant="caption" component="div" sx={{ fontSize: 11, color: 'text.disabled' }}>
                      <i>{formatNotificationDate(notification.payload.emitted_at)}</i>
                    </Typography>
                    <Typography variant="h6" component="div" sx={{ fontSize: 12 }}>
                      {notification.payload.title}
                    </Typography>
                  </Box>
                }
                sx={{ display: 'flex', flexDirection: 'column-reverse', ml: 1.5 }}
              />
            </StyledAccordionSummary>

            <AccordionDetails>
              <Divider />
              <Box
                sx={{
                  top: 0,
                  left: -1,
                  borderRadius: '5px 0 0 5px',
                  bottom: 0,
                  width: 5,
                  position: 'absolute',
                  background: `${getNotificationColor(notification)}`,
                }}
              />
              <Box sx={{ display: 'flex', gap: '5px', flexDirection: 'column', mt: 0.5, padding: '0.5rem' }}>
                <Typography variant="subtitle2" sx={{ fontSize: 13, mb: '5px' }}>
                  {notification.payload.message}
                  <br />
                  <Chip
                    size="small"
                    label={notification.payload.entityName}
                    sx={{ backgroundColor: theme.palette.info.light, color: theme.palette.info.main, mt: '5px' }}
                  />
                </Typography>
                <Typography variant="h6" component="div" sx={{ fontSize: 12 }}>
                  Acción sugerida:
                </Typography>
                <Typography variant="subtitle2" sx={{ fontSize: 13 }}>
                  {notification.payload.suggestedactions}
                </Typography>
              </Box>
            </AccordionDetails>
          </Accordion>
        </ListItem>
      ))}
    </Box>
  );
};

const StyledAccordionSummary = styled(AccordionSummary)(() => ({
  '&.MuiAccordionSummary-root': {
    alignItems: 'flex-start',
    padding: '10px 8px',
  },
  '& .MuiAccordionSummary-content': {
    margin: '5px',
    height: 'auto',
    minHeight: '30px',
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: '5px',
    height: 'auto',
    minHeight: '30px',
  },
  '&.MuiAccordionSummary-root.Mui-expanded': {
    margin: 0,
    height: 'auto',
    minHeight: '60px',
  },
}));

const AvatarBadge = styled(Badge)(({ theme }) => {
  return {
    cursor: 'pointer',
    paddingLeft: '1rem',
    position: 'absolute',
    right: '18px',
    top: '50%',
    '& .MuiBadge-badge': {
      backgroundColor: theme?.palette.primary.main,
      color: theme?.palette.primary.main,
      boxShadow: `0 0 0 2px ${theme?.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        right: '0',
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        border: '1px solid currentColor',
        content: '""',
      },
    },
  };
});

export default NotificationCard;
