/* eslint-disable @typescript-eslint/no-unused-vars */
// i18n
import './modules/locales/i18n';

// scroll bar
import 'simplebar-react/dist/simplebar.min.css';

import { BrowserRouter } from 'react-router-dom';
import Routes from 'modules/routes/Routes.component';
import AuthProvider from 'modules/auth/AuthProvider.component';
import CubeProviderComponent from 'modules/cubeProvider/CubeProvider.component';

import { ThemeSettings, SettingsProvider } from './modules/shared/components/settings';
import HeaderProvider from 'modules/layouts/app/context/Header.context';
//configurator
import { ConfigurationContextProvider } from 'modules/setup-config/Configuration.context';
import { RequestsContextProvider } from 'modules/requests/Requests.context';
import { HomeDashboardsProvider } from 'modules/home/HomeProvider.component';
import { EmployeesProvider } from 'modules/employees/Employees.context';
import { SchedulerProvider } from 'modules/core/providers/Scheduler.provider';
import { SocketProvider } from 'modules/core/providers/webSocket.provider';

const App = () => {
  return (
    <BrowserRouter>
      <HomeDashboardsProvider>
        <AuthProvider>
          <SocketProvider>
            <SettingsProvider>
              <ThemeSettings>
                <HeaderProvider>
                  <ConfigurationContextProvider>
                    <EmployeesProvider>
                      <SchedulerProvider>
                        <RequestsContextProvider>
                          <CubeProviderComponent>
                            <Routes />
                          </CubeProviderComponent>
                        </RequestsContextProvider>
                      </SchedulerProvider>
                    </EmployeesProvider>
                  </ConfigurationContextProvider>
                </HeaderProvider>
              </ThemeSettings>
            </SettingsProvider>
          </SocketProvider>
        </AuthProvider>
      </HomeDashboardsProvider>
    </BrowserRouter>
  );
};

export default App;
