import { useEffect, useState } from 'react';

//hooks
import { useAppDispatch, useAppSelector } from 'store/hooks/store.hooks';
import { useAuth } from 'modules/auth/AuthProvider.component';
import { useNavigate } from 'react-router-dom';

// components
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import { TabsPill } from './tabPills/TabPills.component';
import { Badge, BadgeProps, IconButton, Stack, styled, Typography } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import NotificationCard from './notificationCard/notificationCard.component';
import Scrollbar from 'modules/shared/components/scrollbar/Scrollbar';

// utils & interfaces
import { EnumNotifications } from 'modules/scheduler/interfaces/Drawer.interface';
import { WebSocketNotification } from 'modules/shared/interfaces/notifications.interface';

// services
import { fetchNotifications } from 'store/actions/notifications.actions';

// icons
import NotificationsIcon from '@mui/icons-material/Notifications';

export default function NotificationsDrawer() {
  const [open, setOpen] = useState(false);
  const id = open ? 'simple-popover' : undefined;
  const [notificationsTab, setNotificationsTab] = useState(EnumNotifications.todas);
  const { token } = useAuth();
  const smartNotifications: WebSocketNotification[] = useAppSelector(state => state.notifications.notifications);
  const notificationsUnread = smartNotifications.filter(notification => !notification.payload.wasread);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (token && smartNotifications.length <= 0) {
      dispatch(fetchNotifications());
    }
  }, [dispatch, token]);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const goToNotifications = () => {
    setOpen(false);
    navigate('/notifications');
  };

  const DrawerList = (
    <Box>
      <Box sx={{ width: 400 }} role="presentation">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ pr: 2, py: 2, pb: '15px', pt: '15px' }}
        >
          <List sx={{ width: '100%' }}>
            <Typography
              variant="subtitle1"
              fontWeight={800}
              fontSize={'20px'}
              gap={5}
              sx={{ paddingLeft: '19px', marginBottom: '15px' }}
            >
              Notificaciones
              <Typography
                component="span"
                sx={{
                  color: 'gray',
                  fontSize: '0.7rem',
                  marginLeft: '0.3rem',
                  fontStyle: 'italic',
                  fontWeight: '400',
                  lineHeight: '14px',
                }}
              >
                ({smartNotifications.length})
              </Typography>
            </Typography>
            <Scrollbar
              sx={{ width: '100%', height: 1, paddingLeft: '19px', paddingRight: '19px', marginBottom: '15px' }}
            >
              <TabContext value={notificationsTab}>
                <TabsPill tabIndex={notificationsTab} setTabIndex={setNotificationsTab} />
                <TabPanel value={notificationsTab} sx={{ padding: 0 }}>
                  <NotificationCard tab={notificationsTab} notifications={smartNotifications} />
                </TabPanel>{' '}
              </TabContext>
            </Scrollbar>
          </List>
        </Stack>
        <Divider />
      </Box>
      <Box
        sx={{
          position: 'fixed',
          bottom: '15px',
          width: '400px',
          height: '60px',
          backgroundColor: 'white',
          zIndex: 100,
          cursor: 'pointer',
          borderTop: '1px solid #eee',
          transition: 'background-color 0.3s ease, transform 0.3s ease',
          '&:hover': {
            backgroundColor: '#eee',
          },
          span: {
            fontWeight: 700,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          },
        }}
        onClick={goToNotifications}
      >
        <span> Ver todas</span>
      </Box>
    </Box>
  );

  return (
    <div>
      <StyledIconButton aria-label="notifications" onClick={toggleDrawer(true)}>
        <StyledBadge variant="dot" color="primary" aria-describedby={id} invisible={true}>
          <Badge
            badgeContent={notificationsUnread.length ?? 0}
            sx={{
              '&.MuiBadge-root span': {
                color: '#fff',
                backgroundColor: 'red',
              },
            }}
          >
            <StyledNotificationsIcon sx={{ color: '#0e48a3' }} />
          </Badge>
        </StyledBadge>
      </StyledIconButton>
      <Drawer
        anchor="right"
        open={open}
        onClose={toggleDrawer(false)}
        sx={{
          '& .MuiDrawer-paper': {
            borderRadius: 0,
          },
        }}
        PaperProps={{
          sx: { width: 400 },
        }}
      >
        {DrawerList}
      </Drawer>
    </div>
  );
}

const StyledIconButton = styled(IconButton)`
  &:hover {
    animation: shake 0.5s ease-in-out infinite;
  }

  @keyframes shake {
    0% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(-4px);
    }
    50% {
      transform: translateX(4px);
    }
    75% {
      transform: translateX(-4px);
    }
    100% {
      transform: translateX(0);
    }
  }
`;
const StyledNotificationsIcon = styled(NotificationsIcon)`
  &:hover {
    animation: shake 0.5s ease-in-out infinite;
  }

  @keyframes shake {
    0% {
      transform: translateX(0) rotate(0);
    }
    25% {
      transform: translateX(-2px) rotate(3deg);
    }
    50% {
      transform: translateX(2px) rotate(-3deg);
    }
    75% {
      transform: translateX(-1px) rotate(1.5deg);
    }
    100% {
      transform: translateX(0) rotate(0);
    }
  }
`;
const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    top: 5,
    border: `1px solid ${theme.palette.background.paper}`,
  },
}));
