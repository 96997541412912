const { REACT_APP_API_URL } = process.env;

export const appConfig = {
  API_URL: REACT_APP_API_URL || '',
};

export const NAV = {
  W_BASE: 260,
  W_DASHBOARD: 280,
  W_DASHBOARD_MINI: 88,
  //
  H_DASHBOARD_ITEM: 48,
  H_DASHBOARD_ITEM_SUB: 36,
  //
  H_DASHBOARD_ITEM_HORIZONTAL: 32,
};

export const SOCKET_URL = `${process.env.REACT_APP_API_URL}/events`;
